// tslint:disable object-literal-sort-keys
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

export interface ISEOProps {
  description?: string;
  lang?: string;
  meta?: { name: string; content: any; property?: any }[];
  keywords?: string[];
  title: string;
}

const SEO: React.SFC<ISEOProps> = (
  { description, lang, meta, keywords, title } = {
    keywords: [],
    lang: 'en',
    meta: [],
    title: ''
  }
) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description;
        return (
          <>
            <Helmet
              htmlAttributes={{
                lang: lang || 'en-GB'
              }}
              title={title}
              titleTemplate={`%s | ${data.site.siteMetadata.title}`}
              meta={[
                {
                  name: 'description',
                  content: metaDescription
                },
                {
                  property: 'og:title',
                  content: title
                },
                {
                  property: 'og:description',
                  content: metaDescription
                },
                {
                  property: 'og:type',
                  content: 'website'
                },
                {
                  name: 'twitter:card',
                  content: 'summary'
                },
                {
                  name: 'twitter:creator',
                  content: data.site.siteMetadata.author
                },
                {
                  name: 'twitter:title',
                  content: title
                },
                {
                  name: 'twitter:description',
                  content: metaDescription
                }
              ]
                .concat(
                  keywords && keywords.length > 0
                    ? {
                        name: 'keywords',
                        content: keywords.join(', ')
                      }
                    : []
                )
                .concat(meta || [])}
            />
            <script type="application/ld+json">
              {`{
                "@context": "http://schema.org",
                "@type": "Person",
                "name": "Sara Melo",
                "birthDate": "1991",
                "alumniOf": {
                  "@context": "http://schema.org",
                  "@type": "EducationalOrganization",
                  "name": "Highlands College"
                },
                "jobTitle": "Art Student",
                "additionalName": "sarameloxp",
                "email": "contact@saramelo.com",
                "url": "http://saramelo.com",
                "sameAs": ["https://instagram.com/sarameloxp"],
                "image": "https://saramelo.com/img/sara.png"
              }`}
            </script>
          </>
        );
      }}
    />
  );
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
