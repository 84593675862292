import React from 'react';
import '../scss/layout.scss';
import Footer from './Footer';
import Header from './Header';

const Layout: React.SFC = ({ children }) => (
  <>
    <Header />
    <main>{children}</main>
    <Footer />
  </>
);

export default Layout;
