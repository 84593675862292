import { graphql, Link, StaticQuery } from 'gatsby';
import React, { Component } from 'react';

export interface IHeaderState {
  current: string;
}

class Header extends Component<{}, IHeaderState> {
  public state = {
    current: ''
  };
  public render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                description
              }
            }
          }
        `}
      >
        {data => (
          <header>
            <div>
              <Link to="/" title="Sara Melo" rel="home" id="logo">
                <h1 className="site-title">{data.site.siteMetadata.title}</h1>
                <h2 className="site-description">{data.site.siteMetadata.description}</h2>
              </Link>
            </div>
            <div>
              <ul className="menu__with-separator">
                <li className={this.state.current === '/' ? 'active' : ''}>
                  <Link
                    to="/"
                    getProps={({ isCurrent }): any => {
                      if (isCurrent && !this.state.current) {
                        this.setState({ current: '/' });
                      }
                    }}
                  >
                    Home
                  </Link>
                </li>

                <li className={this.state.current === '/animation' ? 'active' : ''}>
                  <Link
                    to="/animation"
                    getProps={({ isCurrent }): any => {
                      if (isCurrent && !this.state.current) {
                        this.setState({ current: '/animation' });
                      }
                    }}
                  >
                    Animation
                  </Link>
                </li>

                <li className={this.state.current === '/profile' ? 'active' : ''}>
                  <Link
                    to="/profile"
                    getProps={({ isCurrent }): any => {
                      if (isCurrent && !this.state.current) {
                        this.setState({ current: '/profile' });
                      }
                    }}
                  >
                    Profile
                  </Link>
                </li>
              </ul>
            </div>
          </header>
        )}
      </StaticQuery>
    );
  }
}

export default Header;
