import React from 'react';

const Footer = () => (
  <>
    <div className="line">
      <hr />
    </div>
    <footer>
      <div className="footer-content">
        <p className="copyright">
          Copyright &copy; {new Date().getFullYear()} &nbsp;
          <a className="site-link" href="http://saramelo.com" title="Sara Melo" rel="home">
            <span>Sara Melo</span>
          </a>
        </p>
        <p className="credit">
          Developed by &nbsp;<a href="https://leomeloxp.net">leomeloxp</a>
        </p>
      </div>
      <nav>
        <ul className="menu__with-separator">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/animation">Animation</a>
          </li>
          <li>
            <a href="/profile">Profile</a>
          </li>
        </ul>
      </nav>
    </footer>
  </>
);
export default Footer;
